import axios from 'axios'
import { userService } from './user.service'
import { v4 as uuidv4 } from 'uuid'
import Swal from 'sweetalert2'

// Device token initialization
let deviceToken = localStorage.getItem('deviceToken')
if (!deviceToken) {
    deviceToken = uuidv4()
    localStorage.setItem('deviceToken', deviceToken)
}

// API configuration
const API_URL = process.env.VUE_APP_BACKEND_URL
const TOKEN_API = process.env.VUE_APP_TOKEN_API

// Axios instance
const apiClient = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${TOKEN_API}`,
        'Device-Token': deviceToken
    },
    withCredentials: true
})

// Response interceptor
apiClient.interceptors.response.use(
    response => response.data,
    error => {
        const path = window.location.pathname
        if (error.response?.status === 401 && path !== '/logout') {
            window.location.href = '/logout'
            userService.logout()
        }
        return Promise.reject(error.response?.data?.message || error.message)
    }
)

export const methods = {
    post: 'POST',
    get: 'GET',
    put: 'PUT',
    patch: 'PATCH',
    delete: 'DELETE'
}

export function makeRequestOptions(method, path, body, { formData = false, cache = false, simpleFilter = false } = {}) {
    const config = {
        method,
        url: path,
        headers: {
            'Authorization': `Bearer ${TOKEN_API}`,
            'Device-Token': deviceToken
        }
    }

    if (method === 'GET' && body) {
        const params = new URLSearchParams()

        if (!simpleFilter) {
            // Pagination params
            if (body.first !== undefined && body.rows) {
                params.append('page', Math.floor(body.first / body.rows) + 1)
                params.append('limit', body.rows)
            }

            // Sorting params
            if (body.sortField) {
                params.append('sort_by', body.sortField)
                params.append('sort_order', body.sortOrder === 1 ? 'asc' : 'desc')
            }

            // Global search
            if (body.filters?.global?.value) {
                params.append('search', body.filters.global.value)
            }

            // Specific filters
            if (body.filters) {
                Object.entries(body.filters).forEach(([key, filter]) => {
                    if (key !== 'global' && filter.constraints?.[0]?.value !== null) {
                        let { value, matchMode } = filter.constraints[0]
                        if (typeof value === 'boolean') {
                            value = value ? 'true' : 'false'
                        }
                        params.append(`filter[${key}]`, JSON.stringify({ value, matchMode }))
                    }
                })
            }
        } else {
            // Simple filter mode
            Object.entries(body).forEach(([key, value]) => {
                if (value !== null) {
                    params.append(key, value)
                }
            })
        }

        config.params = params
    } else if (!formData && body) {
        config.data = body
    }

    if (formData) {
        delete config.headers['Content-Type']
        config.data = body
    }

    const request = apiClient(config)

    if (cache && method === 'GET') {
        const cacheKey = `${path}?${new URLSearchParams(config.params).toString()}`
        request.then(data => {
            localStorage.setItem(cacheKey, JSON.stringify({ data, timestamp: Date.now() }))
        })
    }

    return request
}

export function makeRequestMultiPart(method, path, formData) {
    return apiClient({
        method,
        url: path,
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const alertDelete = (callback) => {
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger ms-2"
        },
        buttonsStyling: false
    })

    return swalWithBootstrapButtons.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!"
    }).then((result) => {
        if (result.value) {
            return callback()
                .then(() => {
                    swalWithBootstrapButtons.fire(
                        "Deleted!",
                        "Your file has been deleted.",
                        "success"
                    )
                })
                    .catch((error) => {
                        swalWithBootstrapButtons.fire(
                            "Error!",
                            error,
                            "error"
                        )
                        throw error
                    })
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    "Cancelled",
                    "Your data mapping is safe :)",
                    "error"
                )
            }
        })
}

export function transformKBorMBorGB(size) {
    if (size === 0) {
        return '0 KB'
    } else if (size < 1024) {
        return `${(size).toFixed(2)} KB`
    } else if (size < 1024 * 1024) {
        return `${(size / 1024).toFixed(2)} MB`
    } else {
        return `${(size / (1024 * 1024)).toFixed(2)} GB`
    }
}

export function getCurrentDate() {
    // Obtém a data atual no formato YYYY-MM-DD
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda se necessário
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}